window._ = require('lodash');
window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js').default;

import L from 'leaflet';
window.L = L;

require('bootstrap');

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, FreeMode } from "swiper/modules";
Swiper.use([Autoplay, Navigation, Pagination, FreeMode]);
window.Swiper = Swiper;

window.jQueryBridget = require('jquery-bridget');
window.Masonry = require('masonry-layout');

// import lightGallery from 'lightgallery';
// import lgThumbnail from 'lightgallery/plugins/thumbnail'
// import lgZoom from 'lightgallery/plugins/zoom'
// window.lightGallery = lightGallery;
// window.lgThumbnail = lgThumbnail;
// window.lgZoom = lgZoom;

import Lightbox from 'bs5-lightbox';
window.Lightbox = Lightbox;
