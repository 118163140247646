// import 'vanilla-cookieconsent/dist/cookieconsent.umd.js';
import CookieConsent from "vanilla-cookieconsent/dist/cookieconsent.umd.js";

// window.openPreferences = () => {
//     CookieConsent.showPreferences();
// }


window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }

export function openPreferences() {
    CookieConsent.showPreferences();
}

/**
 * All config. options available here:
 * https://cookieconsent.orestbida.com/reference/configuration-reference.html
 */
CookieConsent.run({

    // root: 'body',
    // autoShow: true,
    // disablePageInteraction: true,
    // hideFromBots: true,
    // mode: 'opt-in',
    // revision: 0,

    cookie: {
        name: 'cc_cookie',
        // domain: location.hostname,
        // path: '/',
        // sameSite: "Lax",
        // expiresAfterDays: 365,
    },

    // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
    guiOptions: {
        consentModal: {
            layout: 'cloud inline',
            position: 'middle center',
            equalWeightButtons: false,
            flipButtons: false,

        },
        preferencesModal: {
            layout: 'box',
            equalWeightButtons: false,
            flipButtons: true
        }
    },

    onFirstConsent: ({cookie}) => {
    },

    onConsent: ({cookie}) => {
        // console.log('onFirstConsent fired');
        var consent = {};
        Object.values(cookie.categories).forEach(val => {
            // console.log(val)
            if(val==('necessary')){
                consent["security_storage"] = 'granted';
            }
            if(val==('analytics')){
                consent["analytics_storage"] = 'granted';
            }
            if(val==('functionality')){
                consent["functionality_storage"] = 'granted';
                consent["personalization_storage"] = 'granted';
            }
            if(val==('marketing')){
                consent["ad_personalization"] = 'granted';
                consent["ad_storage"] = 'granted';
                consent["ad_user_data"] = 'granted';
            }

        });
        gtag('consent', 'update', consent);
        window.dataLayer.push({
            event: 'consent_update',
        });
    },

    onChange: ({changedCategories, changedServices}) => {
        var consent = {
            "security_storage": 'denied',
            "analytics_storage": 'denied',
            "functionality_storage": 'denied',
            "personalization_storage": 'denied',
            "ad_personalization": 'denied',
            "ad_storage": 'denied',
            "ad_user_data": 'denied',
        };
        // console.log(CookieConsent.getCookie('categories'))
        Object.values(CookieConsent.getCookie('categories')).forEach(val => {
            // console.log(val)
            switch (val) {
                case 'necessary':
                    consent["security_storage"] = 'granted';
                    break;
                case 'analytics':
                    consent["analytics_storage"] = 'granted';
                    break;
                case 'functionality':
                    consent["functionality_storage"] = 'granted';
                    consent["personalization_storage"] = 'granted';
                    break;
                case 'marketing':
                    consent["ad_personalization"] = 'granted';
                    consent["ad_storage"] = 'granted';
                    consent["ad_user_data"] = 'granted';
                    break;
            }


        });
        gtag('consent', 'update', consent);
        window.dataLayer.push({
            event: 'consent_update',
        });
    },

    onModalReady: ({modalName}) => {
        // console.log('ready:', modalName);
    },

    onModalShow: ({modalName}) => {
        document.querySelector('#cc-main').classList.add('cc--darkmode');
        if (modalName === 'consentModal') {
           const rejectBtnLabel = document.querySelector('.cm__btn.cm__btn--secondary[data-role="necessary"]').innerHTML;
           const rejectBtn = document.createElement('a');
           // const preferenceBtn = document.querySelector('.cm__btn.cm__btn--secondary[data-role="show"]');
           const parentDiv = document.querySelector('.cm__btns')
           rejectBtn.role = 'button';
           rejectBtn.innerHTML = rejectBtnLabel;
           rejectBtn.id = 'rejectBtn';
           parentDiv.insertAdjacentElement('beforeend', rejectBtn);
           rejectBtn.addEventListener('click', () => {
               CookieConsent.acceptCategory([]);
               CookieConsent.hide();
           })
        }
    },

    onModalHide: ({modalName}) => {
        // console.log('hidden:', modalName);
    },

    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^_ga/,   // regex: match all cookies starting with '_ga'
                    },
                    {
                        name: '_gid',   // string: exact cookie name
                    },
                    {
                        name: '_clck',
                    }
                ]
            },

            // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
            // services: {
            //     ga: {
            //         label: 'Google Analytics',
            //         onAccept: () => {},
            //         onReject: () => {
            //             CookieConsent.eraseCookies([/^_ga/, '_gid']);
            //         }
            //     },
            //     clarity: {
            //         label: 'Microsoft Clarity',
            //         onAccept: () => {},
            //         onReject: () => {
            //             CookieConsent.eraseCookies(['_clck']);
            //         }
            //     }
            // }
        },
        functionality: {
            // autoClear: {
            //     cookies: [
            //         {
            //             name: /^_ga/,   // regex: match all cookies starting with '_ga'
            //         },
            //         {
            //             name: '_gid',   // string: exact cookie name
            //         }
            //     ]
            // },
        },
        marketing: {
            autoClear: {
                cookies: [
                    {
                        name: '_fbp',   // string: exact cookie name
                    },
                    {
                        name: '_gcl_au',
                    }
                ]
            },

            // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
            // services: {
            //     gads: {
            //         label: 'Google Ads',
            //         onAccept: () => {},
            //         onReject: () => {
            //             CookieConsent.eraseCookies(['_gcl_au']);
            //         }
            //     },
            //     fbp: {
            //         label: 'Facebook Pixel',
            //         onAccept: () => {},
            //         onReject: () => {
            //             CookieConsent.eraseCookies(['_fbp']);
            //         }
            //     }
            // }
        }
    },

    language: {
        default: 'en',
        autoDetect: 'document',
        translations: {
            it: {
                consentModal: {
                    title: 'Informativa',
                    description: 'Noi e terze parti selezionate utilizziamo cookie o tecnologie simili per finalità tecniche e, con il tuo consenso, anche per le finalità di funzionalità, esperienza, misurazione e marketing (con annunci personalizzati) come specificato nella cookie policy.\n' +
                        '\n' +
                        'Puoi liberamente prestare, rifiutare o revocare il tuo consenso, in qualsiasi momento, accedendo al pannello delle preferenze. Il rifiuto del consenso può rendere non disponibili le relative funzioni.\n' +
                        '\n' +
                        'Usa il pulsante “Accetta” per acconsentire. Usa il pulsante "Rifiuta" per continuare senza accettare.',
                    acceptAllBtn: 'Accetta',
                    acceptNecessaryBtn: 'Rifiuta',
                    showPreferencesBtn: 'Personalizza',
                    closeIconLabel: 'Reject all and close modal',
                    // footer: `
                    //     <a data-bs-toggle="modal" data-bs-target="#privacyPolicyModal" role="button" target="_blank">Privacy Policy</a>
                    //     <a data-bs-toggle="modal" data-bs-target="#cookiePolicyModal" role="button" target="_blank">Cookie Policy</a>
                    // `,
                },
                preferencesModal: {
                    title: 'Personalizza',
                    acceptAllBtn: 'Accetta tutto',
                    acceptNecessaryBtn: 'Rifiuta tutto',
                    savePreferencesBtn: 'Accetta selezione',
                    closeIconLabel: 'Close modal',
                    serviceCounterLabel: 'Service|Services',
                    sections: [
                        {
                            title: 'Le tue preferenze relative alla privacy',
                            description: `Questo pannello ti permette di esprimere alcune preferenze relative al trattamento delle tue informazioni personali.
                                Puoi rivedere e modificare le tue scelte in qualsiasi momento accedendo al presente pannello tramite l’apposito link.
                                Per rifiutare il tuo consenso alle attività di trattamento descritte di seguito, disattiva i singoli comandi o utilizza il pulsante “Rifiuta tutto” e conferma di voler salvare le scelte effettuate.`,
                        },
                        {
                            title: 'Necessari',
                            description: 'Questi strumenti di tracciamento sono strettamente necessari per garantire il funzionamento e la fornitura del servizio che ci hai richiesto e, pertanto, non richiedono il tuo consenso.',

                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary'
                        },
                        {
                            title: 'Misurazione',
                            description: 'Questi strumenti di tracciamento ci permettono di misurare il traffico e analizzare il tuo comportamento per migliorare il nostro servizio.\n',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                caption: 'Tabella cookie',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Dominio',
                                    // desc: 'Description'
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        domain: location.hostname,
                                        // desc: 'Description 1',
                                    },
                                    {
                                        name: '_gid',
                                        domain: location.hostname,
                                        // desc: 'Description 2',
                                    },
                                    {
                                        name: '_clck',
                                        domain: location.hostname,
                                    }
                                ]
                            }
                        },
                        {
                            title: 'Funzionalità',
                            description: 'Questi strumenti di tracciamento abilitano semplici interazioni e funzionalità che ti permettono di accedere a determinate risorse del nostro servizio e ti consentono di comunicare più facilmente con noi.',
                            linkedCategory: 'functionality',
                            // cookieTable: {
                            //     caption: 'Tabella cookie',
                            //     headers: {
                            //         name: 'Cookie',
                            //         domain: 'Dominio',
                            //         // desc: 'Description'
                            //     },
                            //     body: [
                            //         {
                            //             name: '_ga',
                            //             domain: location.hostname,
                            //             // desc: 'Description 1',
                            //         },
                            //     ]
                            // }
                        },
                        {
                            title: 'Marketing',
                            description: 'Questi strumenti di tracciamento ci permettono di fornirti contenuti marketing o annunci personalizzati e di misurarne la performance.',
                            linkedCategory: 'marketing',
                            cookieTable: {
                                caption: 'Tabella cookie',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Dominio',
                                    // desc: 'Description'
                                },
                                body: [
                                    {
                                        name: '_gcl_au',
                                        domain: location.hostname,
                                        // desc: 'Description 1',
                                    },
                                    {
                                        name: '_fbp',
                                        domain: location.hostname,
                                        // desc: 'Description 2',
                                    },
                                ]
                            }
                        },
                        // {
                        //     title: 'More information',
                        //     description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>'
                        // }
                    ]
                }
            },

            en: {
                consentModal: {
                    title: 'Notice',
                    description:
                        'We and selected third parties use cookies or similar technologies for technical purposes and, with your consent, also for functionality, experience, measurement, and marketing purposes (with personalized ads) as specified in the cookie policy.\n' +
                        '\n' +
                        'You are free to give, refuse, or revoke your consent at any time by accessing the preferences panel. Refusing consent may make certain features unavailable.\n' +
                        '\n' +
                        'Use the “Accept” button to give your consent. Use the “Reject” button to continue without accepting.',
                    acceptAllBtn: 'Accept',
                    acceptNecessaryBtn: 'Reject',
                    showPreferencesBtn: 'Customize',
                    closeIconLabel: 'Reject all and close modal',
                    // footer: `
                    //     <a data-bs-toggle="modal" data-bs-target="#privacyPolicyModal" role="button" target="_blank">Privacy Policy</a>
                    //     <a data-bs-toggle="modal" data-bs-target="#cookiePolicyModal" role="button" target="_blank">Cookie Policy</a>
                    // `,
                },
                preferencesModal: {
                    title: 'Customize',
                    acceptAllBtn: 'Accept all',
                    acceptNecessaryBtn: 'Reject all',
                    savePreferencesBtn: 'Accept selection',
                    closeIconLabel: 'Close modal',
                    serviceCounterLabel: 'Service|Services',
                    sections: [
                        {
                            title: 'Your privacy preferences',
                            description: `This panel allows you to express certain preferences regarding the processing of your personal information.
You can review and change your choices at any time by accessing this panel via the dedicated link.
To refuse your consent for the processing activities described below, turn off the individual switches or use the “Reject all” button, then confirm that you want to save the selected choices.`,
                        },
                        {
                            title: 'Necessary',
                            description:
                                'These tracking tools are strictly necessary to ensure the operation and delivery of the service you requested and, therefore, do not require your consent.',

                            // This field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary',
                        },
                        {
                            title: 'Measurement',
                            description:
                                'These tracking tools allow us to measure traffic and analyze your behavior to improve our service.\n',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                caption: 'Cookie Table',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    // desc: 'Description'
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        domain: location.hostname,
                                        // desc: 'Description 1',
                                    },
                                    {
                                        name: '_gid',
                                        domain: location.hostname,
                                        // desc: 'Description 2',
                                    },
                                    {
                                        name: '_clck',
                                        domain: location.hostname,
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Functionality',
                            description:
                                'These tracking tools enable simple interactions and features that allow you to access certain resources of our service and help you communicate with us more easily.',
                            linkedCategory: 'functionality',
                            // cookieTable: {
                            //   caption: 'Cookie Table',
                            //   headers: {
                            //       name: 'Cookie',
                            //       domain: 'Domain',
                            //       // desc: 'Description'
                            //   },
                            //   body: [
                            //       {
                            //           name: '_ga',
                            //           domain: location.hostname,
                            //           // desc: 'Description 1',
                            //       },
                            //   ]
                            // }
                        },
                        {
                            title: 'Marketing',
                            description:
                                'These tracking tools allow us to provide you with marketing content or personalized ads and measure their performance.',
                            linkedCategory: 'marketing',
                            cookieTable: {
                                caption: 'Cookie Table',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    // desc: 'Description'
                                },
                                body: [
                                    {
                                        name: '_gcl_au',
                                        domain: location.hostname,
                                        // desc: 'Description 1',
                                    },
                                    {
                                        name: '_fbp',
                                        domain: location.hostname,
                                        // desc: 'Description 2',
                                    },
                                ],
                            },
                        },
                        // {
                        //   title: 'More information',
                        //   description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>'
                        // }
                    ],
                },
            },

            es: {
                consentModal: {
                    title: 'Aviso',
                    description:
                        'Nosotros y terceros seleccionados utilizamos cookies o tecnologías similares con fines técnicos y, con tu consentimiento, también con fines de funcionalidad, experiencia, medición y marketing (con anuncios personalizados), tal como se especifica en la política de cookies.\n' +
                        '\n' +
                        'Puedes dar, rechazar o revocar tu consentimiento libremente en cualquier momento accediendo al panel de preferencias. Rechazar el consentimiento puede hacer que algunas funciones no estén disponibles.\n' +
                        '\n' +
                        'Utiliza el botón "Aceptar" para otorgar tu consentimiento. Utiliza el botón "Rechazar" para continuar sin aceptar.',
                    acceptAllBtn: 'Aceptar',
                    acceptNecessaryBtn: 'Rechazar',
                    showPreferencesBtn: 'Personalizar',
                    closeIconLabel: 'Rechazar todo y cerrar el modal',
                    // footer: `
                    //     <a data-bs-toggle="modal" data-bs-target="#privacyPolicyModal" role="button" target="_blank">Política de Privacidad</a>
                    //     <a data-bs-toggle="modal" data-bs-target="#cookiePolicyModal" role="button" target="_blank">Política de Cookies</a>
                    // `,
                },
                preferencesModal: {
                    title: 'Personalizar',
                    acceptAllBtn: 'Aceptar todo',
                    acceptNecessaryBtn: 'Rechazar todo',
                    savePreferencesBtn: 'Aceptar selección',
                    closeIconLabel: 'Cerrar modal',
                    serviceCounterLabel: 'Servicio|Servicios',
                    sections: [
                        {
                            title: 'Tus preferencias de privacidad',
                            description: `Este panel te permite expresar algunas preferencias con respecto al tratamiento de tu información personal.
Puedes revisar y modificar tus elecciones en cualquier momento accediendo a este panel mediante el enlace correspondiente.
Para rechazar tu consentimiento a las actividades de tratamiento descritas a continuación, desactiva los controles individuales o utiliza el botón “Rechazar todo” y confirma que deseas guardar las opciones seleccionadas.`,
                        },
                        {
                            title: 'Necesarias',
                            description:
                                'Estas herramientas de seguimiento son estrictamente necesarias para garantizar el funcionamiento y la prestación del servicio que has solicitado y, por lo tanto, no requieren tu consentimiento.',
                            linkedCategory: 'necessary',
                        },
                        {
                            title: 'Medición',
                            description:
                                'Estas herramientas de seguimiento nos permiten medir el tráfico y analizar tu comportamiento para mejorar nuestro servicio.\n',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                caption: 'Tabla de cookies',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Dominio',
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        domain: location.hostname,
                                    },
                                    {
                                        name: '_gid',
                                        domain: location.hostname,
                                    },
                                    {
                                        name: '_clck',
                                        domain: location.hostname,
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Funcionalidad',
                            description:
                                'Estas herramientas de seguimiento habilitan interacciones y funcionalidades simples que te permiten acceder a ciertos recursos de nuestro servicio y te ayudan a comunicarte con nosotros más fácilmente.',
                            linkedCategory: 'functionality',
                        },
                        {
                            title: 'Marketing',
                            description:
                                'Estas herramientas de seguimiento nos permiten ofrecerte contenido de marketing o anuncios personalizados y medir su rendimiento.',
                            linkedCategory: 'marketing',
                            cookieTable: {
                                caption: 'Tabla de cookies',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Dominio',
                                },
                                body: [
                                    {
                                        name: '_gcl_au',
                                        domain: location.hostname,
                                    },
                                    {
                                        name: '_fbp',
                                        domain: location.hostname,
                                    },
                                ],
                            },
                        },
                    ],
                },
            },

            fr: {
                consentModal: {
                    title: 'Avis',
                    description:
                        'Nous et certains tiers sélectionnés utilisons des cookies ou des technologies similaires à des fins techniques et, avec votre consentement, également à des fins de fonctionnalité, d’expérience, de mesure et de marketing (avec des publicités personnalisées), comme indiqué dans la politique de cookies.\n' +
                        '\n' +
                        'Vous pouvez librement donner, refuser ou révoquer votre consentement à tout moment en accédant au panneau des préférences. Le refus de consentement peut rendre certaines fonctionnalités indisponibles.\n' +
                        '\n' +
                        'Utilisez le bouton “Accepter” pour donner votre consentement. Utilisez le bouton “Refuser” pour continuer sans accepter.',
                    acceptAllBtn: 'Accepter',
                    acceptNecessaryBtn: 'Refuser',
                    showPreferencesBtn: 'Personnaliser',
                    closeIconLabel: 'Tout refuser et fermer la fenêtre',
                    // footer: `
                    //     <a data-bs-toggle="modal" data-bs-target="#privacyPolicyModal" role="button" target="_blank">Politique de confidentialité</a>
                    //     <a data-bs-toggle="modal" data-bs-target="#cookiePolicyModal" role="button" target="_blank">Politique de cookies</a>
                    // `,
                },
                preferencesModal: {
                    title: 'Personnaliser',
                    acceptAllBtn: 'Tout accepter',
                    acceptNecessaryBtn: 'Tout refuser',
                    savePreferencesBtn: 'Accepter la sélection',
                    closeIconLabel: 'Fermer la fenêtre',
                    serviceCounterLabel: 'Service|Services',
                    sections: [
                        {
                            title: 'Vos préférences en matière de confidentialité',
                            description: `Ce panneau vous permet d'exprimer certaines préférences concernant le traitement de vos informations personnelles.
Vous pouvez revoir et modifier vos choix à tout moment en accédant à ce panneau via le lien dédié.
Pour refuser votre consentement aux activités de traitement décrites ci-dessous, désactivez les options individuelles ou utilisez le bouton “Tout refuser” et confirmez que vous souhaitez enregistrer vos sélections.`,
                        },
                        {
                            title: 'Nécessaires',
                            description:
                                'Ces outils de suivi sont strictement nécessaires pour assurer le fonctionnement et la fourniture du service que vous avez demandé et ne requièrent donc pas votre consentement.',
                            linkedCategory: 'necessary',
                        },
                        {
                            title: 'Mesure',
                            description:
                                'Ces outils de suivi nous permettent de mesurer le trafic et d’analyser votre comportement pour améliorer notre service.\n',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                caption: 'Tableau des cookies',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domaine',
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        domain: location.hostname,
                                    },
                                    {
                                        name: '_gid',
                                        domain: location.hostname,
                                    },
                                    {
                                        name: '_clck',
                                        domain: location.hostname,
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Fonctionnalité',
                            description:
                                'Ces outils de suivi activent des interactions et fonctionnalités simples qui vous permettent d’accéder à certaines ressources de notre service et de communiquer plus facilement avec nous.',
                            linkedCategory: 'functionality',
                        },
                        {
                            title: 'Marketing',
                            description:
                                'Ces outils de suivi nous permettent de vous proposer du contenu marketing ou des publicités personnalisées et d’en mesurer l’efficacité.',
                            linkedCategory: 'marketing',
                            cookieTable: {
                                caption: 'Tableau des cookies',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domaine',
                                },
                                body: [
                                    {
                                        name: '_gcl_au',
                                        domain: location.hostname,
                                    },
                                    {
                                        name: '_fbp',
                                        domain: location.hostname,
                                    },
                                ],
                            },
                        },
                    ],
                },
            },

            pt: {
                consentModal: {
                    title: 'Aviso',
                    description:
                        'Nós e determinados terceiros selecionados utilizamos cookies ou tecnologias semelhantes para fins técnicos e, com o seu consentimento, também para fins de funcionalidade, experiência, medição e marketing (com anúncios personalizados), conforme especificado na política de cookies.\n' +
                        '\n' +
                        'Você pode livremente conceder, recusar ou revogar seu consentimento a qualquer momento, acessando o painel de preferências. A recusa do consentimento pode tornar algumas funcionalidades indisponíveis.\n' +
                        '\n' +
                        'Use o botão “Aceitar” para dar o seu consentimento. Use o botão “Recusar” para continuar sem aceitar.',
                    acceptAllBtn: 'Aceitar',
                    acceptNecessaryBtn: 'Recusar',
                    showPreferencesBtn: 'Personalizar',
                    closeIconLabel: 'Recusar tudo e fechar',
                    // footer: `
                    //     <a data-bs-toggle="modal" data-bs-target="#privacyPolicyModal" role="button" target="_blank">Política de Privacidade</a>
                    //     <a data-bs-toggle="modal" data-bs-target="#cookiePolicyModal" role="button" target="_blank">Política de Cookies</a>
                    // `,
                },
                preferencesModal: {
                    title: 'Personalizar',
                    acceptAllBtn: 'Aceitar tudo',
                    acceptNecessaryBtn: 'Recusar tudo',
                    savePreferencesBtn: 'Aceitar seleção',
                    closeIconLabel: 'Fechar modal',
                    serviceCounterLabel: 'Serviço|Serviços',
                    sections: [
                        {
                            title: 'Suas preferências de privacidade',
                            description: `Este painel permite que você expresse algumas preferências em relação ao tratamento de suas informações pessoais.
Você pode revisar e modificar suas escolhas a qualquer momento acessando este painel por meio do link dedicado.
Para recusar seu consentimento para as atividades de tratamento descritas abaixo, desative os comandos individuais ou use o botão “Recusar tudo” e confirme que deseja salvar as opções selecionadas.`,
                        },
                        {
                            title: 'Necessários',
                            description:
                                'Essas ferramentas de rastreamento são estritamente necessárias para garantir o funcionamento e a prestação do serviço que você solicitou e, portanto, não exigem seu consentimento.',
                            linkedCategory: 'necessary',
                        },
                        {
                            title: 'Medição',
                            description:
                                'Essas ferramentas de rastreamento nos permitem medir o tráfego e analisar o seu comportamento para melhorar nosso serviço.\n',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                caption: 'Tabela de cookies',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domínio',
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        domain: location.hostname,
                                    },
                                    {
                                        name: '_gid',
                                        domain: location.hostname,
                                    },
                                    {
                                        name: '_clck',
                                        domain: location.hostname,
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Funcionalidade',
                            description:
                                'Essas ferramentas de rastreamento habilitam interações e funcionalidades simples que permitem que você acesse determinados recursos do nosso serviço e ajudem você a se comunicar de forma mais fácil conosco.',
                            linkedCategory: 'functionality',
                        },
                        {
                            title: 'Marketing',
                            description:
                                'Essas ferramentas de rastreamento nos permitem fornecer conteúdo de marketing ou anúncios personalizados e medir seu desempenho.',
                            linkedCategory: 'marketing',
                            cookieTable: {
                                caption: 'Tabela de cookies',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domínio',
                                },
                                body: [
                                    {
                                        name: '_gcl_au',
                                        domain: location.hostname,
                                    },
                                    {
                                        name: '_fbp',
                                        domain: location.hostname,
                                    },
                                ],
                            },
                        },
                    ],
                },
            }
        }
    }
});
