module.exports = {
    init: () => {
        $(document).ready(function(){
            RebuildClasses();
            window.addEventListener("resize", RebuildClasses);

            $('.tx-show').on('click', function (e){
                e.preventDefault();
                if (e.altKey !== false) {
                    window.open($(this).attr('data-url'), '_blank').focus();
                }
            });
        });
    },
    homepage: () => {
        $(document).ready(function(){
            //https://swiperjs.com/swiper-api#parameters -> qui ci sono tutti i parametri modificabili

            //Cities
            const swiperCities = new Swiper('.swiper.cities', {
                direction: 'horizontal',
                initialSlide: 0,
                loop: true,
                loopAdditionalSlides: isOnMobile ? 0 : 5,
                centeredSlides: true,
                slidesPerView: "auto",
                grabCursor: true,
                spaceBetween: 10,
                slideToClickedSlide: !isOnMobile,
                pagination: {
                    dynamicBullets: true,
                    clickable: true,
                    el: '.swiper.cities .swiper-pagination',
                },
                navigation: false,
                scrollbar: false,
                breakpoints: {
                    //768: {slidesPerView: 3.5},
                    992: {slidesPerView: 3.5},
                    1200: {slidesPerView: 4},
                    1400: {slidesPerView: 5.5}
                }
            });

            //Content
            RebuildHomeContent();
            window.addEventListener("resize", RebuildHomeContent);

            //Cards
            RebuildSwiperHomeCards();
            window.addEventListener("resize", RebuildSwiperHomeCards);

            //Search Modal
            RebuildSearchModal();
            window.addEventListener("resize", RebuildSearchModal);

            //Location Box
            RebuildLocationBox();
            window.addEventListener("resize", RebuildLocationBox);
        });
    },
    header: () => {
        $(document).ready(function() {
            //Header Text
            const swiperHeaderText = new Swiper('.swiper.header-text', {
                direction: 'vertical',
                initialSlide: 0,
                loop: true,
                allowTouchMove: false,
                autoplay: true,
                centeredSlides: true,
                slidesPerGroupSkip: 0,
                slidesPerView: 1,
                grabCursor: false,
                spaceBetween: 20,
                pagination: false,
                navigation: false,
                scrollbar: false
            });

            //Header city menu
            const swiperCityHeader = new Swiper('.swiper.city_scroll_wrap', {
                direction: 'horizontal',
                /*effect: 'coverflow',*/
                initialSlide: 0,
                loop: false,
                allowTouchMove: true,
                autoplay: false,
                centeredSlides: false,
                mousewheelReleaseOnEdges: true,
                mousewheel: true,
                slidesPerView: "auto",
                grabCursor: true,
                spaceBetween: 1,
                pagination: false,
                paginationClickable: false,
                navigation: { //per disabilitare impostare su false e rimuvere l'html sopra
                    nextEl: '.swiper.city_scroll_wrap .swiper-button-next',
                    prevEl: '.swiper.city_scroll_wrap .swiper-button-prev',
                },
                scrollbar: false,
                on: {
                    beforeInit: (swiper) => {
                        $(".swiper.city_scroll_wrap .swiper-slide.active").detach().insertBefore(".swiper.city_scroll_wrap .swiper-slide:first");
                    },
                    afterInit: (swiper) => {
                        $('.swiper.city_scroll_wrap .swiper-button-prev').hide();
                    },
                    slideChange: (swiper) => {
                        if(swiper.activeIndex !== 0){
                            $('.swiper.city_scroll_wrap .swiper-button-prev').show();
                        }else{
                            $('.swiper.city_scroll_wrap .swiper-button-prev').hide();
                        }
                    }
                }
            });
        });
    },
    footer: () => {
        $(document).ready(function() {
            //Reviews
            const swiperReviews = new Swiper('.swiper.reviews', {
                direction: 'horizontal',
                initialSlide: 0,
                loop: true,
                loopAdditionalSlides: isOnMobile ? 0 : 4,
                centeredSlides: true,
                slidesPerView: "auto",
                grabCursor: true,
                spaceBetween: 10,
                slideToClickedSlide: !isOnMobile,
                pagination: {
                    dynamicBullets: true,
                    clickable: true,
                    el: '.swiper.reviews .swiper-pagination',
                },
                navigation: false,
                scrollbar: false,
                breakpoints: {
                    //670: {slidesPerView: 1.5, spaceBetween: 20,},
                    //768: {slidesPerView: 2.1, spaceBetween: 20,},
                    992: {slidesPerView: 2.5, spaceBetween: 40,},
                    1400: {slidesPerView: 3.5, spaceBetween: 40,},
                    1600: {slidesPerView: 4.5, spaceBetween: 40,}
                }
            });

            RebuildFooterAccordion();
            window.addEventListener("resize", RebuildFooterAccordion);
            var footerCollapseGroup = $('#footerCollapse');
            footerCollapseGroup.on('show.bs.collapse', '.collapse', function () {
                footerCollapseGroup.find('.collapse.show').collapse('hide');
            });
        });
    },
    shop: () => {
        $(document).ready(function() {

            //Widget
            RebuildShopBookingWidget();
            window.addEventListener("resize", RebuildShopBookingWidget);

            //Leggi altro..
            $('.collapse_trigger a').on('click', function (e) {
                $(this).closest('.collapse_text .text_wrap').removeClass('text_wrap');
                $(this).closest('.collapse_text').removeClass('collapse_text');
                $(this).parent().hide();
            });

            RebuildShopGallery();
            window.addEventListener("resize", RebuildShopGallery);

            // Locker Cards
            RebuildSwiperShopLockerCards();
            window.addEventListener("resize", RebuildSwiperShopLockerCards);

            //Shops
            const swiperShops = new Swiper('.swiper.shops', {
                direction: 'horizontal',
                initialSlide: 0,
                loop: true,
                centeredSlides: true,
                slidesPerView: "auto",
                grabCursor: true,
                spaceBetween: 10,
                pagination: {
                    dynamicBullets: true,
                    clickable: true,
                    el: '.swiper.shops .swiper-pagination',
                },
                navigation: false,
                scrollbar: false,
                breakpoints: {
                    768: {initialSlide: 1, slidesPerView: 2.5, spaceBetween: 30, centeredSlides: true},
                    992: {initialSlide: 1, slidesPerView: 2.5, spaceBetween: 30, centeredSlides: true},
                    1200: {initialSlide: 0, slidesPerView: 4, spaceBetween: 0, centeredSlides: false},
                    1400: {initialSlide: 0, slidesPerView: 4, spaceBetween: 0, centeredSlides: false}
                }
            });
        });
    },
    couponModal: (shop_id) => {
        let couponModalLoaded = false;
        //Fix per non farlo rilevare a google altrimenti non gli piace
        $(document).on("click keydown keyup mousemove touchmove", function() {
            if(!couponModalLoaded) {
                setTimeout(function() {
                    $('#couponPopupModal').modal("show");
                }, 1500);
                couponModalLoaded = true;
            }
        });

        $(document).ready(function(){
            // setTimeout(function(){
            //     let savedDate = localStorage.getItem("coupon_modal_"+shop_id);
            //     if(savedDate){
            //         let modalDate = new Date(savedDate);
            //         let openModalDate = new Date();
            //         openModalDate.setDate(openModalDate.getDate() - 1);
            //
            //         if(modalDate > openModalDate)
            //             return;
            //     }
            //     $('#couponPopupModal').modal("show");
            // }, 2000);
            $('#couponPopupModal .btn_coupon').on('click', function(){
                let btn = $(this);
                let text = btn.data('coupon');
                window.App.template.copyTextToClipboard(text);
                btn.addClass('copied');
                setTimeout(function(){
                    btn.removeClass('copied');
                }, 3000);
            });
            // $("#couponPopupModal").on('hide.bs.modal', function(){
            //     let now = new Date();
            //     localStorage.setItem("coupon_modal_"+shop_id, now.toISOString());
            // });
        });
    },
    copyTextToClipboard: (text) => {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
        }, function(err) {
            console.error('copyTextToClipboard: Could not copy text: ', err);
        });
    }
};

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
    } catch (err) {
        console.error('fallbackCopyTextToClipboard: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
}

let isOnMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

/*** Responsive Class ***/
function RebuildClasses() {
    $('[data-md-class]').each(function () {
        if (window.innerWidth >= 768) {
            $(this).addClass($(this).attr('data-md-class'))
        }else{
            $(this).removeClass($(this).attr('data-md-class'))
        }
    });
    $('[data-sm-class]').each(function () {
        if (window.innerWidth <= 768) {
            $(this).addClass($(this).attr('data-sm-class'))
        }else{
            $(this).removeClass($(this).attr('data-sm-class'))
        }
    });
}

/** Booking Widget **/
let bookingWidgetInit = false;
function RebuildShopBookingWidget(){
    if (window.innerWidth >= 768) {
        if(!bookingWidgetInit){
            let bookingWidget = $('.booking-widget');
            var iframe = $('<iframe/>').addClass('booking-widget');
            $.each(bookingWidget.data(), function (key, attr) {
                iframe.attr(key, attr);
            });
            bookingWidget.replaceWith(iframe);

            window.addEventListener('message', function(event) {
                if (event.origin !== iframe.attr('from')) return;
                if(event.data.event_id === 'bookingWidgetLoaded'){
                    iframe.fadeIn(); //Mostro l'iframe solo se si è caricato tutto correttamente
                }
                if (event.data.event_id === 'showModal') {
                    $('#'+event.data.modal).modal({backdrop: 'static'});
                    $('#'+event.data.modal).modal('show');
                }
                if (event.data.event_id === 'goToLockersDetail') {
                    //console.log('goToLockersDetail', event.data);
                    $('#lockerModal-'+event.data.locker_type).modal('show');
                    //$('html,body').animate({scrollTop: $('#accordionDetails').offset().top},'slow');
                }
                if (event.data.event_id === 'goToCheckout') {
                    if(event.data.url !== null){
                        setTimeout(function(){
                            window.location.href = event.data.url;
                        }, 500);
                    }
                }
            }, false);

            console.log('shopBookingWidgetLoaded');
            bookingWidgetInit = true;
        }
    }
}

/** Responsive Home Content **/
let homeContentDskInit = true;
function RebuildHomeContent() {
    if (window.innerWidth >= 768) {
        if(!homeContentDskInit){
            $('.main_content').html($('.main_content_mobile').html());
            $('.main_content_mobile').html("");
            homeContentDskInit = true;
        }
    }else{
        if(homeContentDskInit){
            $('.main_content_mobile').html($('.main_content').html());
            $('.main_content').html("");
            homeContentDskInit = false;
        }
    }
}


/*** Search Modal ***/
let searchModalDskInit = false;
function RebuildSearchModal(){
    if (window.innerWidth >= 768) {
        $('.search_modal .tab_container').removeClass('accordion');
        $('.search_modal .tab_container .tab-pane').each(function() {
            let currentTab = $(this);
            $(this).find('.tab_item').each(function (){

                let button = $(this).find('.tab_item_button');
                button.attr('data-bs-toggle', 'none');
                button.addClass('collapsed');
                button.off('click').on('click', function(){
                    currentTab.find('.tab_item_content').each(function(){
                        $(this).removeClass('show').hide();
                    });
                    $($(this).attr('data-bs-target')).addClass('show').fadeIn("fast");
                    currentTab.find('.tab_item_button').each(function(){
                        $(this).removeClass('active');
                    });
                    $(this).addClass('active');
                });

                let content = $(this).find('.tab_item_content');
                content.removeClass('collapse');
                content.removeClass('show').hide();

                let tabcontent = currentTab.find('.tab_content');
                if(tabcontent.length <= 0)
                    currentTab.find('.tab_list').after('<div class="col-md-8 col-sm-12 tab_content"></div>');

                currentTab.find('.tab_content').append(content.prop('outerHTML'));
                content.remove();
            });

            if(currentTab.find('.tab_item_content.show').length <= 0)
                currentTab.find('.tab_item_content').first().addClass('show').fadeIn("fast");

            if(currentTab.find('.tab_item_button.active').length <= 0)
                currentTab.find('.tab_item_button').first().addClass('active');
        });

        searchModalDskInit = true;
    }else if(searchModalDskInit){
        $('.search_modal .tab_container').addClass('accordion');
        $('.search_modal .tab_content .tab_item_content').each(function(){
            let content = $(this).addClass('collapse').removeClass('show');
            let button = $('[data-bs-target="#'+$(this).attr('id')+'"]');
            button.removeClass('active');
            button.off('click');
            button.attr('data-bs-toggle', 'collapse');
            button.attr('aria-expanded', 'false');
            button.addClass('collapsed');
            content.attr('style', '');
            button.after(content.prop('outerHTML'));
            content.remove();
        });
        searchModalDskInit = false;
    }
    RebuildClasses();
}

let locationBoxElements = [];
let locationBoxDskInit = false;
let locationBoxMobileHtml = null;
function RebuildLocationBox(){
    let locationBox = $('.location_box .location_content');
    if (window.innerWidth >= 576) {

        if(locationBoxMobileHtml === null)
            locationBoxMobileHtml = locationBox.html();

        let content = $('<ul class="shop_list d-sm-inline px-3 m-0 d-inline-flex flex-wrap"></ul>');

        $('.location_box .location_title').each(function() {
            content.append('<li class="d-none d-sm-inline-flex title">'+$(this).text().toString()+'</li>');
            $($(this).attr('data-bs-target')+' .location_link').each(function(){
                content.append('<li class="d-sm-inline-flex">'+$(this).html()+'</li>');
            });
        });

        if(!locationBoxDskInit)
            locationBox.html(content);

        locationBoxDskInit = true;
    }else if(locationBoxDskInit){

        locationBox.html(locationBoxMobileHtml);
        locationBoxDskInit = false;
    }
}


/*** Home Cards ***/
let swiperHomeCardsInit = false;
let swiperHomeCards;
function RebuildSwiperHomeCards() {
    if (window.innerWidth <= 768) {
        $('.home-cards').addClass('swiper');
        if($('.home-cards .swiper-pagination').length <= 0){
            $('.home-cards').append('<div class="swiper-pagination"></div>');
        }
        $('.home-cards .row').addClass('swiper-wrapper').addClass('pb-5').removeClass('row');
        $('.home-cards .col-lg-4.col-sm-6').addClass('swiper-slide')
            .removeClass('col-lg-4').removeClass('col-sm-6');


        if (!swiperHomeCardsInit) {
            swiperHomeCardsInit = true;
            swiperHomeCards = new Swiper('.swiper.home-cards', {
                direction: 'horizontal',
                initialSlide: 0,
                loop: true,
                centeredSlides: true,
                slidesPerView: "auto",
                grabCursor: true,
                spaceBetween: 10,
                pagination: {
                    dynamicBullets: true,
                    clickable: true,
                    el: '.swiper.home-cards .swiper-pagination',
                },
                navigation: false,
                scrollbar: false,
            });
        }
    } else if (swiperHomeCardsInit) {
        swiperHomeCards.destroy();
        $('.home-cards').removeClass('swiper');
        $('.home-cards .swiper-pagination').remove();
        $('.home-cards .swiper-wrapper').removeClass('swiper-wrapper').addClass('row');
        $('.home-cards .swiper-slide').removeClass('swiper-slide').addClass('col-lg-4').addClass('col-sm-6');
        swiperHomeCardsInit = false;
    }
}


// Swiper Shop Locker Cards
let swiperShopLockerCardsInit = false;
let swiperShopLockerCards;
function RebuildSwiperShopLockerCards() {
    if (window.innerWidth <= 768) {
        let divLockerCards = $('.locker_cards');
        divLockerCards.addClass('swiper expanded');

        if($('.locker_cards .swiper-pagination').length <= 0){
            divLockerCards.append('<div class="swiper-pagination"></div>');
        }

        $('.locker_cards .row').addClass('swiper-wrapper pb-5').removeClass('row d-md-flex justify-content-center');
        $('.locker_cards .col-md-4.col-sm-6').addClass('swiper-slide').removeClass('col-md-4 col-sm-6');

        if (!swiperShopLockerCardsInit) {
            swiperShopLockerCardsInit = true;

            swiperShopLockerCards = new Swiper('.swiper.locker_cards', {
                direction: 'horizontal',
                initialSlide: 0,
                loop: false,
                centeredSlides: true,
                slidesPerView: 1.2,
                grabCursor: true,
                spaceBetween: 10,
                pagination: {
                    dynamicBullets: true,
                    clickable: true,
                    el: '.swiper.locker_cards .swiper-pagination',
                },
                navigation: false,
                scrollbar: false,
            });
        }
    } else if (swiperShopLockerCardsInit) {
        swiperShopLockerCards.destroy();
        $('.locker_cards').removeClass('swiper expanded');
        $('.locker_cards .swiper-pagination').remove();
        $('.locker_cards .swiper-wrapper').removeClass('swiper-wrapper pb-5').addClass('row d-md-flex justify-content-center');
        $('.locker_cards .swiper-slide').removeClass('swiper-slide').addClass('col-md-4 col-sm-6');
        swiperShopLockerCardsInit = false;
    }
}


/*** Footer ***/
function RebuildFooterAccordion() {
    if (window.innerWidth >= 992) {
        $('.footer_accordion .accordion-item .accordion-button').attr('aria-expanded', true).attr('data-bs-toggle', false).removeClass('collapsed');
        $('.footer_accordion .accordion-item .accordion-collapse').removeClass('collapse').addClass('show');
    } else {
        $('.footer_accordion .accordion-item .accordion-button').attr('aria-expanded', false).attr('data-bs-toggle', "collapse").addClass('collapsed');
        $('.footer_accordion .accordion-item .accordion-collapse').addClass('collapse').removeClass('show');
    }
}

/** Shop Gallery ***/
let shopGalleryElements = [];
let shopGalleryMobileInit = false;
let shopGalleryDskHtml = null;
let shopGalleryModalInit = false;
let shopGalleryLightBox = null;

function RebuildShopGallery(){
    if(shopGalleryElements.length <= 0){
        $('.shop-gallery img.gallery-item').each(function() {
            shopGalleryElements.push($($(this).parent().parent().prop('outerHTML')));
        });
    }

    let gallerySize = "xl";
    if(shopGalleryElements.length > 0){
        if (window.innerWidth <= 768) {
            if(!shopGalleryMobileInit) {
                shopGalleryDskHtml = $('.shop-gallery').html();

                $('.shop-gallery').addClass('swiper').html("");
                $('.shop-gallery').append('<div class="swiper-pagination"></div>');
                $('.shop-gallery').append('<div class="swiper-wrapper"></div>');

                let html = '';
                for (let i = 0; i < shopGalleryElements.length; i++) {
                    html += '<div class="swiper-slide">'+shopGalleryElements[i].attr('style', '').html()+'</div>';
                }
                $('.shop-gallery .swiper-wrapper').html(html);

                //Gallery
                const swiperGallery = new Swiper('.swiper.shop-gallery', {
                    direction: 'horizontal',
                    /*effect: 'coverflow',*/
                    initialSlide: 0,
                    loop: true,
                    allowTouchMove: true,
                    autoplay: false,
                    touchRatio: 0.3,
                    centeredSlides: false,
                    slidesPerView: 1,
                    grabCursor: true,
                    spaceBetween: 0,
                    pagination: { //per disabilitare impostare su false e rimuvere l'html sopra
                        clickable: true,
                        dynamicBullets: true,
                        el: '.swiper.shop-gallery .swiper-pagination',
                    },
                    paginationClickable: true,
                    navigation: { //per disabilitare impostare su false e rimuvere l'html sopra
                        nextEl: '.swiper.shop-gallery .swiper-button-next',
                        prevEl: '.swiper.shop-gallery .swiper-button-prev',
                    },
                    scrollbar: { //per disabilitare impostare su false e rimuvere l'html sopra
                        el: '.swiper.shop-gallery .swiper-scrollbar',
                    },
                });

                shopGalleryMobileInit = true;
                shopGalleryModalInit = false;
                gallerySize = "fullscreen";
            }
        }else if(shopGalleryMobileInit){
            $('.shop-gallery').html(shopGalleryDskHtml);
            shopGalleryMobileInit = false;
            shopGalleryModalInit = false;
        }

        if(!shopGalleryModalInit) {
            document.querySelectorAll('.gallery-toggle').forEach((el) => el.addEventListener('click', (e) => {
                e.preventDefault();
                shopGalleryLightBox = new Lightbox(el, {size: gallerySize});
                shopGalleryLightBox.show();
            }));
            shopGalleryModalInit = true;

            $('.gallery-link').off('click').on('click', function(e) {
                document.getElementsByClassName('gallery-toggle')[0].click();
            });
        }
    }
}
