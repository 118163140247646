import './bootstrap';

let fieldsList = [];
let setField = (key, value) => {
    fieldsList[key] = value;
};
let getField = (key = null) => {
    if (key === null)
        return fieldsList;
    if (typeof fieldsList[key] !== "undefined")
        return fieldsList[key];
    return null;
};

window.App = {
    set: setField,
    get: getField,
    template: require("./components/template"),
    cookieconsent: require("./cookieconsent-config"),
};
